<template>
  <div class="container-table">
    <table>
      <tr style="position: sticky; top: 0;">
        <th></th>
        <th class="table-header">
          <div><p class="header-text">Energie (kcal)</p></div>
        </th>
        <th class="table-header-multiple">
          <div class="container-table-header">
            <div><p class="header-text">Vetten (g)</p></div>
            <div><span class="sub-header">waarvan verzadigde vetten</span></div>
            <div><span class="sub-header">waarvan enkelvoudig onverzadigd</span></div>
            <div><span class="sub-header">waarvan meervoudig onverzadigd</span></div>
          </div>

        </th>
        <th class="table-header">
          <div><p class="header-text">Vezels</p></div>
        </th>
        <th class="table-header-multiple">
          <div class="container-table-header">
            <div><p class="header-text">Koolhydraten (g)</p></div>
            <div><span class="sub-header">waarvan suikers</span></div>
          </div>
        </th>
        <th class="table-header">
          <div><p class="header-text">Eiwitten (g)</p></div>
        </th>
        <th class="table-header">
          <div><p class="header-text">Zout (g)</p></div>
        </th>
      </tr>
      <tr class="row-content" v-for="dish in dishes">
        <td class="table-content table-name">{{dish.name}}</td>
        <td class="table-content">
          <div>{{ dish.full.energie }}</div>
        </td>
        <td class="table-content">
          <div class="container-waarvan">
            <div>{{dish.full.vetten.totaal}}</div>
            <div>{{dish.full.vetten.verzadigd}}</div>
            <div>{{dish.full.vetten.enkelvoudig}}</div>
            <div>{{dish.full.vetten.meervoudig}}</div>
          </div>
        </td>
        <td class="table-content">
          <div>{{dish.full.vezels}}</div>
        </td>
        <td class="table-content">
          <div class="container-waarvan">
            <div>{{dish.full.koolhydraten.totaal}}</div>
            <div>{{dish.full.koolhydraten.suiker}}</div>
          </div>
        </td>
        <td class="table-content">
          <div>{{dish.full.eiwitten}}</div>
        </td>
        <td class="table-content">
          <div>{{dish.full.zout}}</div>
        </td>
      </tr>
    </table>
    <p style="color: darkgray">* Voedingsinformatie per 100g/ml</p>
  </div>
</template>

<script>
export default {
  name: "NutritionTable",
  props: {
    dishes: Array
  }
}
</script>

<style scoped>

.container-table{
  margin: 10px;
}
th, td {
  padding: 0;
}

.table-content {
  width: 50px;
}

.table-content div{
  text-align: center;
}

.sub-header {
  color: #535050;
  font-size: smaller;
}

.table-name {
  width: 300px;
}

.table-header {

  background: #ebece7;
  border-radius: 10px;
  vertical-align: bottom;
  padding: 10px 0;
}

.table-header-multiple {
  border-radius: 10px;
  background: #ebece7;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  height: 200px;
}

.container-table-header {
  display: flex;
  writing-mode: vertical-rl;
  flex-direction: column;
  transform: rotate(180deg);
}

.container-table-header div {
  text-align: start;
  width: 30px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.table-header div {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
  width: 100%;
}

.table-content {
  padding: 10px;
}

.container-waarvan{
  display: flex;
}

.container-waarvan div{
  width: 30px;
  display: flex;
  justify-content: center;
}

.header-text{
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px){
  .table-header, .table-header-multiple{
    padding: 10px 0;
    font-size: small;
  }
  .table-header div {
    padding: 0;
    margin: 0;
  }
  .table-name{
    font-weight: bold;
  }
  .table-content{
    font-size: x-small;
    padding: 5px;
  }
}
</style>