<template>
  <div class="button-container" @click="onClick"
       :style="{offsetWidth: width, justifyContent: justifyContent, alignItems: alignItems, backgroundColor: bgColorContainer, cursor: 'pointer', display: 'flex', flexDirection: flexDirection, borderRadius: borderRadius}">
    <div class="image-container" style="margin: 0; padding: 0" v-if="imageUrl !== ''">
      <img :style="{padding: imagePadding, backgroundColor: bgColorImage, margin: imageMargins, borderRadius: borderRadius}"
           :width="imageWidth" :height="imageHeight"
           :src="require('@/assets/' + imageUrl + '')" :alt="imageAltText">
    </div>
    <p :style="{margin: textMargins,fontSize: fontSize+'px', color: textColor}">{{ contentText }}</p>
  </div>
</template>

<script>
export default {
  name: "WWButton",
  props: {
    clickAction: {
      default: undefined,
      type: String
    },
    width: {
      default: 'auto',
      type: String
    },
    imageUrl: {
      default: '',
      type: String,
    },
    imageAltText: {
      default: '',
      type: String,
    },
    imageWidth: Number,
    imageHeight: Number,
    fontSize: {
      default: 16,
      type: Number,
    },
    textColor: {
      default: 'white',
      type: String,
    },
    bgColorContainer: {
      default: 'var(--primary)',
      type: String,
    },
    bgColorImage: {
      default: 'white',
      type: String,
    },
    contentText: {
      default: '',
      type: String,
    },
    flexDirection: {
      default: 'row',
      type: String,
    },
    borderRadius: {
      default: '10px',
      type: Number,
    },
    alignItems: {
      default: 'center',
      type: String,
    },
    textMargins: {
      default: '20px',
      type: String,
    },
    imageMargins: {
      default: '10px',
      type: String,
    },
    imagePadding: {
      default: '0px',
      type: String
    },
    justifyContent: {
      default: 'center',
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    onClick(){
      if(this.clickAction !== undefined){
        window.open(this.clickAction)
      }
    }
  }
}
</script>

<style scoped>
</style>