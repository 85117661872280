<template>
  <div class="container-culture">
    <h1>CULTURE</h1>
    <p>Some content about culture</p>
  </div>
</template>

<script>
export default {
name: "CareersCulture"
}
</script>

<style scoped>
h1{
  color: white;
  text-align: center;
}
p{
  color: white;
}
</style>