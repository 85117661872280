<template>
  <div class="container-table">
    <table>
      <tr style="position: sticky; top: 0;">
        <th></th>
        <th class="table-header">
          <div><p class="allergen-header">Gluten</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Schaaldieren</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Ei</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Vis</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Pinda</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Soja</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Melk</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Noten</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Selderij</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Mosterd</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Sesam</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Sulfiet</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Lupine</p></div>
        </th>
        <th class="table-header">
          <div><p class="allergen-header">Weekdieren</p></div>
        </th>
        
      </tr>
      <tr class="row-content" v-for="dish in dishes">
        <td class="table-name">{{dish.name}}</td>
        <td class="table-content">
          <div v-if="dish.allergen.gluten"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.schaaldieren"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.ei"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.vis"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.pinda"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.soja"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.melk"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.noten"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.selderij"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.mosterd"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.sesam"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.sulfiet"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.lupine"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        <td class="table-content">
          <div v-if="dish.allergen.weekdieren"><CheckboxBoolean color="red" width="12"/></div>
        </td>
        
      </tr>
    </table>
  </div>
</template>

<script>
import CheckboxBoolean from "@/components/CheckboxBoolean";
export default {
  name: "NutritionTable",
  components: {CheckboxBoolean},
  props: {
    dishes: Array
  }
}
</script>

<style scoped>
th, td {
  padding: 0;
}

.table-header {
  border-radius: 10px;
  background: #ebece7;
  vertical-align: bottom;
  padding: 10px 0;
  height: 150px;
}

.container-table-header div {
  text-align: start;
  width: 30px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.table-header div {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
  width: 100%;
}

.table-content {
  text-align: center;
  padding: 10px;
}

table{
  margin-bottom: 20px;
}

.allergen-header{
  padding: 10px;
  margin: 0;
}
@media screen and (max-width: 600px){
  .table-content{
    padding: 0;
  }
  .allergen-header{
    font-size: 11px;
    padding: 2px;
  }
  .table-name {
    font-size: 11px;
  }
}
</style>