<template>
  <div class="content-section-1" :style="{
      backgroundImage: 'url(' + require('@/assets/gelukskoekjes/header_dark.jpg') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
    }">
    <div class="navbar">
      <NewNavbar/>
    </div>
    <div class="container-leftover-content">
      <h1 id="slogan">DE CHINEES VAN NU</h1>
    </div>
  </div>
</template>
<script>
import NewNavbar from "../NewNavbar"
import WWButton from "@/components/WWButton"
import ButtonText from "../buttons/ButtonText";

export default {
  name: 'GelukskoekjesSection1',
  components: {ButtonText, NewNavbar, WWButton}
}
</script>
<style scoped>

#slogan {
  text-align: center;
  font-weight: 400;
  font-size: 90px;
  line-height: 1.067;
  color: white;
  padding: 0 0 100px 0;
  font-family: scriptorama-markdown, iskra-regular, sans-serif;
}

.container-leftover-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin: 200px 0;
}

footer {
  height: 140px;
}

@media only screen and (max-width: 600px) {
  #slogan {
    font-size: 28px;
  }

  .container-leftover-content {
    margin: 0;
    min-height: 350px;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px){
  #slogan{
    font-size: 45px;
  }
}
</style>
