<template>
  <div id="container-geluksnummers">
    <div id="container-geluksnummers-grid">

      <div id="container-geluksnummers-grid-left">
        <h1>GELUKSNUMMERS</h1>
        <p>In de Chinese cultuur hebben getallen een rijke culturele geschiedenis, zo worden bepaalde getallen beschouwd
          als gelukbrengend. Bij Willie Wong geloven wij sterk dat je geluk kunt vermenigvuldigen door het te delen,
          daarom delen wij de aankomende tijd onze geluksnummers met jou. Verzilver nu jouw geluksnummer bij Willie Wong
          of via onze website. Wil jij een geluksnummer ontvangen? Volg ons dan op: Instagram: williewong_nl en
          Facebook: Willie Wong</p>
      </div>
      <div id="container-geluksnummers-grid-right">
        <div id="flex-right">
          <img id="gelukskoekjes" src="@/assets/fortune_cookies/current.jpg" alt="Gelukskoekjes"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GelukskoekjesSection4"
}
</script>

<style scoped>
#container-geluksnummers-grid-left h1 {
  color: var(--primary);
  font-weight: 400;
  font-size: 48px;
  font-family: scriptorama-markdown, iskra-regular, sans-serif;
}

#container-geluksnummers-grid-left p {
  font-weight: 400;
  font-size: 20px;
}

#container-geluksnummers-grid-right {
  text-align: right;
  width: 100%;
}

#container-geluksnummers-grid {
  padding: 0 240px 150px 240px;
  display: grid;
  grid: "container-geluksnummers-grid-left container-geluksnummers-grid-right"1fr/
1fr 1fr;
  grid-gap: 50px;
}

#gelukskoekjes {
  width: max-content;
}

@media only screen and (max-width: 600px) {
  #container-geluksnummers-grid-left h1 {
    font-size: 36px;
  }

  #container-geluksnummers-grid {
    display: flex;
    flex-direction: column;
    padding: 50px;
  }

  #container-geluksnummers-grid-right {
    width: auto;
  }

  #gelukskoekjes {
    width: 100%;
  }
}


@media only screen and (min-width: 600px) and (max-width: 1050px) {
  #container-geluksnummers-grid-left h1 {
    font-size: 36px;
  }

  #container-geluksnummers-grid {
    grid-gap: 20px;
  }

  #container-geluksnummers-grid {
    padding: 0 50px 50px 50px;
  }

  #container-geluksnummers-grid-right img {
    width: 220px;
  }

  #container-geluksnummers-grid-right {
    text-align: right;
    width: 100%;
    margin-top: 100px;
  }

  #container-geluksnummers-grid-right p {
    font-size: 12px;
  }

  #container-geluksnummers-grid-left p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1250px) {
  #container-geluksnummers-grid {
    padding: 0 50px 50px 50px;
  }

  #container-geluksnummers-grid-right img {
    width: 360px;
  }
}

</style>
