import { createRouter, createWebHistory } from 'vue-router'
import Homepage from "../views/Homepage";
import OnsMenuContent from "@/components/OnsMenuContent";
import NutritionalInformationContent from "@/components/NutritionalInformationContent";
import NutritionTableFull from "@/components/NutritionTableFull";
import NutritionTableAllergen from "@/components/NutritionTableAllergen";
import Careers from "@/components/Careers";
import Onsverhaal from "@/components/Onsverhaal";
import Gelukskoekjes from "../components/gelukskoekjes/Gelukskoekjes";
import HomepageContent from "../components/HomepageContent";

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
    children: [
      {
        path: '',
        component: HomepageContent
      },
      {
        path: 'OnsMenu',
        component: OnsMenuContent
      },
      {
        path: '/Contact',
        name: 'Careers',
        component: Careers
      },
      {
        path: '/Geluksnummer',
        name: 'Gelukskoekjes',
        component: Gelukskoekjes
      },
      {
        path: 'NutritionalInformation',
        component: NutritionalInformationContent,
        children: [
          {
            path: '/NutritionalInformation/Full',
            component: NutritionTableFull
          },
          {
            path: '/NutritionalInformation/Allergen',
            component: NutritionTableAllergen
          }
        ]
      },
      {
        path: '/OnsVerhaal',
        component: Onsverhaal
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
