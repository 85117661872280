<template>
  <router-view/>
  <Cookies/>
</template>

<style>
:root {
  --primary: #CD001A;
  --highlight-yellow: #f2d45c;
  --highlight-blue: #369ea1;
}

@font-face {
  font-family: 'concave-tuscan-condensed';
  src: url("assets/fonts/concave_tuscan_condensed/font.woff") format('woff'),
  url("assets/fonts/concave_tuscan_condensed/font.woff2") format('woff2');
}

@font-face {
  font-family: 'scriptorama-markdown';
  src: url("assets/fonts/scriptorama_markdown/ScriptoramaMarkdownJF.otf") format('opentype'),
  url("assets/fonts/scriptorama_markdown/ScriptoramaMarkdownJF.ttf") format('truetype');
}

@font-face {
  font-family: 'iskra-regular';
  src: url("assets/fonts/iskra/regular/font.woff") format('woff'),
  url("assets/fonts/iskra/regular/font.woff2") format('woff2');
}

@font-face {
  font-family: 'iskra-bold';
  src: url("assets/fonts/iskra/bold/font.woff") format('woff'),
  url("assets/fonts/iskra/bold/font.woff2") format('woff2');
}

* {
  font-family: iskra-regular, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}
</style>
<script>
import Cookies from "@/components/Cookies";
import FortunecookiePopup from "./components/popup/FortunecookiePopup";

export default {
  components: {FortunecookiePopup, Cookies},
}
</script>
