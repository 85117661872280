<template>
  <div class="navbar">
    <div class="container-content">
      <div class="navbar-items">
        <div class="navbar-items-left">
          <div class="item-our-food navbar-item" @click="openWindow('https://amstelveen.williewong.nl/#menu')">
            <p>Menu</p>
          </div>
          <div class="item-our-story navbar-item" @click="openWindow('https://amstelveen.williewong.nl/informatie')">
            <p>Ons verhaal</p>
          </div>
          <div class="item-share-luckynumber navbar-item" @click="navigateTo('Geluksnummer')">
            <p>Geluksnummer</p>
          </div>
          <div class="item-werkenbijwilliewong navbar-item" @click="navigateTo('Contact')">
            <p>Vacatures</p>
          </div>
        </div>
        <div class="navbar-items-right">

          <div class="item-contact navbar-item" @click="navigateTo('Contact')">
            <p>Contact opnemen</p>
          </div>
          <div class="item-bestel-hier navbar-item" @click="openWindow('https://amstelveen.williewong.nl/#menu')">
            <div class="bestel-hier-desktop">
              <ButtonText content-text="Online bestellen" width="156px" height="48px" font-size="18"/>
            </div>
            <div class="bestel-hier-tablet">
              <ButtonText content-text="Online bestellen" width="124px" height="28px" font-size="14"/>
            </div>
            <div class="bestel-hier-tablet-small">
              <ButtonText content-text="Online bestellen" width="112px" height="22px" font-size="13"/>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-logo">
        <img class="image-full" src="@/assets/logo.svg" alt="Willie Wong logo" @click="navigateTo('')">
      </div>
    </div>
    <div class="bestel-hier-mobile" @click="openWindow('https://amstelveen.williewong.nl/#menu')">
      <ButtonText height="28px" width="100px" font-size="12" content-text="Online bestellen" text-color="white"
                  bg-color-container="var(--primary)"/>
    </div>
  </div>

</template>

<script>
import ButtonText from "./buttons/ButtonText";

export default {
  name: "NewNavbar",
  components: {ButtonText},
  methods: {
    openWindow(url) {
      window.open(url)
    },
    navigateTo(path) {
      this.$router.replace('/' + path)
      this.closeNav()
    },
  }
}
</script>

<style scoped>

.navbar {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 80px;
  position: relative;
  border-radius: 5px;
  background: white;
}

.navbar-items-left, .navbar-items-right {
  display: flex;
}

.navbar-items-left div {
  display: flex;
  align-items: center;
}

.navbar-items {
  display: flex;
  width: 100%;
  padding: 0 50px;
  justify-content: space-between;
}

.navbar-item {
  padding: 20px;
}

.navbar-item:hover {
  cursor: pointer;
}

.navbar-logo {
  position: absolute;
}

.image-full {
  width: 175px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.bestel-hier-mobile, .bestel-hier-tablet, .bestel-hier-tablet-small {
  display: none;
}

.item-bestel-hier{
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 720px) {
  .navbar {
    height: 260px;
  }

  .navbar-logo {
    top: -70px;
  }

  .image-full {
    width: 85px;
    height: 60px;
    margin: 0 0 0 0;
    padding: 0;
    cursor: pointer;
  }

  .container-content {
    width: auto;
    margin: 10px 50px;
  }

  .navbar-item {
    align-self: center;
    padding: 0 10px;
  }

  .navbar-items {
    text-align: center;
    padding: 0 10px;
  }

  .navbar-item p {
    font-size: 12px;
  }

  .item-bestel-hier{
    display: none;
  }
  .navbar {
    flex-direction: column;
  }

  .bestel-hier-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 721px) and (max-width: 992px) {
  .image-full {
    width: 85px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .container-content {
    width: 100%;
    margin: 0 50px;
  }

  .navbar-item {
    padding: 0 10px;
  }

  .navbar-items {
    padding: 0 10px;
  }

  .navbar-item p {
    font-size: 12px;
  }
  .bestel-hier-desktop{
    display: none;
  }

  .bestel-hier-tablet-small{
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1350px) {
  .image-full {
    width: 125px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .container-content {
    margin: 0 50px;
  }

  .navbar-item {
    padding: 0 10px;
  }

  .bestel-hier-desktop {
    display: none;
  }

  .bestel-hier-tablet{
    display: block;
  }
}
</style>
