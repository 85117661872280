<template>
  <div class="content" :style="{
      backgroundImage: 'url(' + require('@/assets/gelukskoekjes/header_dark.jpg') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }">
    <div class="navbar">
      <NewNavbar/>
    </div>
    <div class="container-career">
      <div class="container-contact">
        <div class="container-contact-left">
          <h2>Contact</h2>
          <h4>Contact informatie</h4>
          <p>Willie Wong<br>
            Amsterdamseweg 412<br>
            1181 BT Amstelveen</p>
          <p><a href="tel:0203638283">020 3638283</a></p>
          <p><a href="mailto:amstelveen@williewong.nl">amstelveen@williewong.nl</a></p>
          <p><a href="https://maps.google.com?daddr=Amsterdamseweg+412+Amstelveen">Routebeschrijving</a></p>

        </div>
        <div class="container-contact-right">
          <iframe title="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2438.988071025873!2d4.855146415683786!3d52.316218959063804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5e129827204cb%3A0x94803d56e9ee29d4!2sWillie%20Wong%20Amstelveen!5e0!3m2!1sen!2snl!4v1631186882993!5m2!1sen!2snl" width="600" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <Footer/>
  </footer>
</template>

<script>
import CareersCulture from "@/components/CareersCulture";
import CareerPaths from "@/components/CareerPaths";
import Navbar from "./Navbar";
import Footer from "./Footer";
import NewNavbar from "./NewNavbar";

export default {
  name: "Careers",
  components: {NewNavbar, Footer, Navbar, CareerPaths, CareersCulture}
}
</script>

<style scoped>
.container-contact{
  background-color: white;
  border-radius: 10px;
  color: var(--primary);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.container-contact-left{
  padding-right: 100px;
}

.container-contact a {
  color: var(--primary);
}
.content {
  min-height: calc(100vh - 140px);
}

footer{
  height: 140px;
}
.container-career {
  width: 100%;
  margin: 100px auto auto auto;
  justify-content: space-evenly;
  display: flex;
  align-self: center;
}

@media screen and (max-width: 600px) {
  .container-contact {
    flex-direction: column;
    margin-bottom: 50px;
  }
  iframe{
    width: 300px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  iframe{
    width: 400px;
  }
}
</style>
