<template>
  <div id="container-footer">
    <div class="navbar-logo">
      <img class="image-full" src="@/assets/logo.svg" alt="Willie Wong logo" @click="navigateTo('')">
    </div>
    <div id="grid-footer">
      <div id="grid-column-1" class="column">
        <p @click="callAction('tel:0203638283')">020 36 38 283</p>
      </div>
      <div id="grid-column-2" class="column" @click="openUrl('http://maps.google.com/?q=WillieWong Amstelveen')" >
        <p><strong>Willie Wong</strong></p><br>
        <p>Amsterdamseweg 412</p>
        <p>1181 BT, Amstelveen</p>
      </div>
      <div id="grid-column-3" class="column">
        <p><strong>Wij zijn open!</strong></p><br>
        <p>woensdag t/m zondag</p>
        <p>van 17:00 tot 22:00</p>
      </div>
      <div id="grid-column-4">
        <div>
          <img @click="openUrl('https://instagram.com/williewong_nl?utm_medium=copy_link')" class="icon" src="@/assets/instagram.png" alt="instagram">
          <img @click="openUrl('https://www.facebook.com/pages/category/Asian-Restaurant/Willie-Wong-111492781164638/')" class="icon" src="@/assets/facebook.png" alt="facebook">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TelephoneSVG from "@/components/TelephoneSVG";
import BelOns from "@/components/BelOns";
import WWButton from "@/components/WWButton";

export default {
  name: "Footer",
  components: {WWButton, BelOns, TelephoneSVG},
  methods: {
    openUrl(url) {
      window.open(url)
    },
    callAction(action){
      location.href = action
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
}

#container-footer {
  position: relative;
  color: white;
  text-align: center;
  background: black;
  padding: 50px 0;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#grid-footer {
  display: flex;
  justify-content: space-evenly;
}

#grid-column-4{
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  filter: brightness(0) invert(1);
  width: 24px;
  height: 24px;
  margin: 10px;
  cursor: pointer;
}
.image-full{
  width: 178px;
  height: 126px;
}
.navbar-logo{
  position: absolute;
  top: -63px;
  left: calc(50% - 89px);
}

@media only screen and (max-width: 600px) {
  #grid-footer{
    flex-direction: column;
  }

  .column{
    margin: 20px 0;
  }

  .image-full{
    width: 100px;
    height: 126px;
  }
  .navbar-logo{
    position: absolute;
    top: -63px;
    left: calc(50% - 50px);
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px){
  .image-full{
    width: 100px;
    height: 126px;
  }
  .navbar-logo{
    position: absolute;
    top: -63px;
    left: calc(50% - 50px);
  }
}
</style>
