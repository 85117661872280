<template>
  <div id="container-section-3">
    <div id="container-section-3-grid">
      <div id="container-section-3-grid-left">
        <h1 id="content-text-left">DE CHINEES VAN NU</h1>
        <div id="container-section-3-draak">
          <img id="draak" src="@/assets/gelukskoekjes/draak.png" alt="draak"/>
        </div>
      </div>
      <div id="container-section-3-grid-right">
        <div id="grid-right">
          <div id="grid-right-top"></div>
          <div id="grid-right-middle">
            <p id="right-text">Willie Wong is waar de liefde voor Aziatisch eten en Nederlandse smaken samenkomen, met
              haar mengelmoes van eetculturen is er voor iedereen altijd wat lekkers. Wij willen de oer-Hollandse
              Chinees-Indische keuken een nieuw leven inblazen.<br><br>Bij Willie Wong eet je de welbekende
              Chinees-Indische gerechten, van de lekkerste babi pangang tot saté ajam, maar dan in een modern jasje. Wij
              bereiden gerechten met aandacht voor kwaliteit, gezondheid en duurzaamheid.</p>
          </div>
          <div id="grid-right-bottom">
            <ButtonText content-text="Bestel nu" click-action="https://amstelveen.williewong.nl/#menu"
                        bg-color-container="white"
                        width="175px"
                        font-size="16"
                        height="52px"
                        text-color="var(--primary)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonText from "../buttons/ButtonText";

export default {
  name: "GelukskoekjesSection3",
  components: {ButtonText}
}
</script>

<style scoped>

#content-text-left {
  font-family: scriptorama-markdown, iskra-regular, sans-serif;
  font-weight: 400;
  font-size: 42px;
}

#container-section-3 {
  background: var(--primary);
  padding: 75px 240px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

#container-section-3-grid {
  width: 100%;
  display: grid;
  grid: "container-section-3-grid-left container-section-3-grid-right"1fr/
1fr 1fr;
  grid-gap: 200px;
}

#grid-right {
  display: grid;
  grid: "grid-right-top"1fr
"grid-right-middle"2fr
"grid-right-bottom"1fr/
1fr;
}

#right-text {
  font-size: 20px;
  font-weight: 400;
}

#draak {
  width: 490px;
  position: absolute;
  top: 300px;
  left: -160px;
  -webkit-filter: invert(0);
  filter: invert(0);
}

#container-section-3-grid-left {
  position: relative;
}

@media only screen and (max-width: 600px) {
  #container-section-3 {
    padding: 50px;
    text-align: center;
    margin-bottom: 0;
  }

  #container-section-3-grid {
    display: flex;
    flex-direction: column;
  }

  #grid-right {
    display: flex;
    flex-direction: column;
  }

  #draak {
    position: relative;
    width: 250px;
    top: 0;
    left: 0;
  }

  #grid-right-bottom {
    margin: auto;
  }

  #content-text-left {
    font-size: 36px;
  }
}
@media only screen and (max-width: 426px) {
  #content-text-left {
    font-size: 28px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  #container-section-3 {
    padding: 75px 30px;
    margin-bottom: 100px;
  }

  #container-section-3-grid {
    grid-gap: 20px;
  }

  #right-text {
    font-size: 16px;
    margin-top: 100px;
  }

  #container-section-3 h1 {
    font-size: 28px;
  }

  #grid-right {
    display: flex;
    flex-direction: column;
  }

  #grid-right-top {
    display: none;
  }

  #draak {
    width: 250px;
    top: 200px;
    left: 0;
  }

  #right-text {
    margin: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  #container-section-3 {
    padding: 75px 50px;
  }

  #draak {
    top: 400px;
    left: 0;
  }

  #container-section-3-grid {
    grid-gap: 50px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1450px) {
  #container-section-3-grid{
    grid-gap: 25px;
  }
}

@media only screen and (min-width: 2000px) {
  #draak {
    top: 200px;
  }
}
</style>
