<template>
  <div class="container-bel-ons" @click="makePhoneCall">
    <TelephoneSVG :width="width" :height="height" :color="color"/>
    <p :style="{fontSize: font_size+'px'}">Bel ons!</p>
  </div>
</template>

<script>
import TelephoneSVG from "@/components/TelephoneSVG";
export default {
  name: "BelOns",
  components: {TelephoneSVG},
  methods: {
    makePhoneCall() {
      location.href = 'tel:0203638283'
    }
  },
  props: {
    color: String,
    width: Number,
    height: Number,
    font_size: Number
  }
}
</script>

<style scoped>
.container-bel-ons{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  color: white;
  margin-left: 10px;
}


</style>