<template>
  <div class="content" :style="{
      backgroundImage: 'url(' + require('@/assets/willie_wong_dark_background_550x440.jpeg') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }">
    <div class="navbar">
      <Navbar/>
    </div>
    <h1 class="title" style="text-align: center; color: white">ONS MENU</h1>
    <div class="container-ons-menu">
      <WWButton click-action="https://amstelveen.williewong.nl/" image-margins="15px" font-size="42"
                image-height="80" image-alt-text="Fortune cookies"
                content-text="Bestel hier!" image-url="fortune_cookies.png"/>
      <br><br>
      <router-link to="/NutritionalInformation/Full">
        <WWButton font-size="24" content-text="Voedingsinformatie"/>
      </router-link>
    </div>
    <div class="container-ons-menu menu-small">
      <WWButton click-action="https://amstelveen.williewong.nl/" image-margins="15px 0 15px 15px" font-size="20"
                image-height="50" image-alt-text="Fortune cookies"
                content-text="Bestel hier!" image-url="fortune_cookies.png"/>
      <br><br>
      <router-link to="/NutritionalInformation/Full">
        <WWButton font-size="18" text-margins="10px" content-text="Voedingsinformatie"/>
      </router-link>
    </div>
  </div>
  <footer>
    <Footer/>
  </footer>
</template>

<script>
import MenuItemBlock from "@/components/MenuItemBlock";
import WWButton from "@/components/WWButton";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default {
  name: "OnsMenuContent",
  components: {Navbar, Footer, WWButton, MenuItemBlock},
  data() {
    return {
      items: []
    }
  },
  created() {
    let menuitemsdata = [
      ['Entrees', 'entrees.jpg'],
      ['Sides', 'sidedishes.jpg'],
      ['Appetizers', 'appetizers.jpg'],
      ['Willie Promise', 'panda-promise-thmb.jpg']
    ]
    let menuitems = []
    menuitemsdata.forEach(data => {
      menuitems.push(this.createMenuItemTiles(data[0], data[1]))
    })
    this.items = menuitems
  },
  methods: {
    createMenuItemTiles(title, image_name) {
      return {
        title: title,
        image_name: image_name
      }
    },
    openWindow(url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.title {
  margin-bottom: 50px;
}

.content {
  min-height: calc(100vh - 140px);
}

footer {
  height: 140px;
}

a {
  text-decoration: none;
  color: black;
}

.container-ons-menu {
  width: 450px;
  display: flex;
  flex-direction: column;
  margin: 100px auto 50px auto;
}

.blocks div {
  margin: 0 20px;
}

.menu-small {
  display: none;
}

@media screen and (max-width: 600px) {
  .container-ons-menu:not(:last-child) {
    display: none;
  }

  .menu-small {
    display: flex;
    width: 250px;
  }
}
</style>
