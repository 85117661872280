<template>
  <GelukskoekjesSection1/>
  <GelukskoekjesSection2/>
  <GelukskoekjesSection3/>
  <GelukskoekjesSection4/>
  <GelukskoekjesSection5/>
  <footer>
    <Footer/>
  </footer>
  <FortunecookiePopup/>
</template>

<script>
import GelukskoekjesSection1 from "./GelukskoekjesSection1";
import GelukskoekjesSection2 from "./GelukskoekjesSection2";
import GelukskoekjesSection3 from "./GelukskoekjesSection3";
import GelukskoekjesSection4 from "./GelukskoekjesSection4";
import Footer from "../Footer";
import GelukskoekjesSection5 from "./GelukskoekjesSection5";
import FortunecookiePopup from "../popup/FortunecookiePopup";

export default {
  name: "Gelukskoekjes",
  components: {
    FortunecookiePopup,
    GelukskoekjesSection5,
    GelukskoekjesSection1, GelukskoekjesSection4, GelukskoekjesSection3, GelukskoekjesSection2, Footer},
}
</script>

<style scoped>

</style>
