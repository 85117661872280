<template>
  <div class="content-block">
    <img :src="path" :alt="'Image '+item.title"/>
    <p>{{ item.title }}</p>
  </div>
</template>

<script>
import {food_images_folder_name} from '@/js/global_variables';

export default {
  name: "MenuItemBlock",
  props: {
    item: Object,
  },
  data(){
    return{
      path: null
    }
  },
  created() {
    this.path = require('@/assets/'+food_images_folder_name+'/'+this.item.image_name)
  }
}
</script>

<style scoped>
.content-block{
  width: 150px;
  cursor: pointer;
}

img {
  width: 100%;
}
p{
  text-align: center;
}
</style>