<template>
  <div class="container-checkmark">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         viewBox="0 0 240.608 240.608" :style="{'enable-background':'new 0 0 240.608 240.608;', width: width, height: height}" xml:space="preserve">
<path :style="{fill: color}"
      d="M208.789,29.972l31.819,31.82L91.763,210.637L0,118.876l31.819-31.82l59.944,59.942L208.789,29.972z"
/>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: "CheckboxBoolean",
  props: {
    color: String,
    width: Number,
    Height: Number
  }
}
</script>

<style scoped>
</style>