<template>
  <div class="content" :style="{
      backgroundImage: 'url(' + require('@/assets/willie_wong_dark_background_550x440.jpeg') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }">
    <div class="navbar">
      <NewNavbar/>
    </div>
    <h1 style="margin: 20px" id="slogan">Heb je al gegeten?</h1>
    <div class="container-leftover-content">
      <div class="container-order-here">
        <WWButton click-action="https://amstelveen.williewong.nl/" image-margins="15px" font-size="30"
                  image-height="50"
                  image-alt-text="Fortune cookies"
                  content-text="Bestel hier!" image-url="fortune_cookies.png"/>
      </div>
    </div>
  </div>
  <footer>
    <Footer/>
  </footer>
</template>

<script>
import WWButton from "@/components/WWButton";
import Footer from "./Footer";
import NewNavbar from "./NewNavbar";

export default {
  name: "HomepageContent",
  components: {NewNavbar, Footer, WWButton},
}
</script>

<style scoped>
.content {
  min-height: calc(100vh - 140px);
}

.homepage-content img {
  width: 50%;
  align-self: center;
}

#slogan {
  color: white;
  text-align: center;
  font-size: xxx-large;
}

.container-leftover-content {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.container-order-here {
  max-width: max-content;
}

footer {
  height: 140px;
}

@media screen and (max-width: 800px) {

}


@media screen and (min-width: 801px) and (max-width: 1200px) {

}
</style>
