<template>
  <div class="container-careerpaths">
    <h1>CAREERS</h1>
    <p>Some content about career paths</p>
  </div>
</template>

<script>
export default {
name: "CareerPaths"
}
</script>

<style scoped>
h1{
  color: white;
  text-align: center;
}
p{
  color: white;
}
</style>