<template>
  <div class="container-popup">
    <p class="sluiten">Sluiten</p>
    <h2>Jouw geluksnummer</h2>
    <div id="container-popup-content">
      <div id="text-left">
        <p>Heb jij je geluksnummer al gevonden? Vul dit nummer dan in als actiecode bij je bestelling en ontvang een kleine verrassing van Willie Wong. </p>
      </div>
      <div id="img-right">
        <img src="@/assets/fortune_cookies/current.jpg" alt="gelukskoekjes">
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "FortunecookiePopup",
  mounted() {
    const cookieBox = document.querySelector(".container-popup"),
        acceptBtn = cookieBox.querySelector(".sluiten");

    acceptBtn.onclick = () => {
      //setting cookie for 1 day, after one month it'll be expired automatically
      document.cookie = "FortunecookiesPopupAccepted=true; max-age=" + 60 * 60 * 12;
      if (document.cookie) { //if cookie is set
        cookieBox.classList.add("hide"); //hide cookie box
      } else { //if cookie not set then alert an error
        alert("Cookie can't be set! Please unblock this site from the cookie setting of your browser.");
      }
    }
    let checkCookie = document.cookie.indexOf("FortunecookiesPopupAccepted=true"); //checking our cookie
    //if cookie is set then hide the cookie box else show it
    checkCookie !== -1 ? cookieBox.classList.add("hide") : cookieBox.classList.remove("hide");


    $(".container-popup").animate({
      bottom: '0',
    }, "slow")
  }
}
</script>

<style scoped>
.container-popup{
  position: sticky;
  left: 100%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px 4px rgb(0 0 0 / 15%);
  z-index: 999;
}

.hide{
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: all 0.3s ease;
  display: none;
}

h2{
  font-family: scriptorama-markdown, iskra-regular, 'sans-serif';
  font-size: 32px;
  font-weight: 400;
  color: var(--primary);

}

#container-popup-content{
  display: flex;
  justify-content: space-between;
}

#img-right{
  padding-left:10px;
}

#text-left{
  padding-right: 10px;
}

#img-right img{
  max-width: 200px;
}

.sluiten{
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 500px){
  .container-popup{
    max-width: 200px;
  }
  #container-popup-content{
    justify-content: center;
    flex-direction: column;
  }

  h2{
    font-size: 24px;
  }
}


@media only screen and (min-width: 500px) and (max-width: 992px){
  .container-popup{
    max-width: 250px;
  }
  #container-popup-content{
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (max-height: 450px) {
  .container-popup{
    max-width: 350px;
  }

  p{
    font-size: 12px;
  }

  h2{
    font-size: 24px;
  }

  #img-right img{
    max-width: 125px;
  }

  #container-popup-content{
    justify-content: center;
    flex-direction: row;
  }
}
</style>
