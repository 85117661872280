<template>
  <div class="wrapper">
    <img src="../assets/fortune_cookies.png" alt="">
    <div class="content">
      <header>(Fortune)cookies consent</header>
      <p>We use (fortune)cookies to ensure you get the best experience on our website.</p>
      <div class="buttons">
        <button class="item">I accept</button>
        <a href="https://www.cookiesandyou.com/" target="_blank" class="item">Learn more</a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: "Cookies",
  mounted() {
    const cookieBox = document.querySelector(".wrapper"),
        acceptBtn = cookieBox.querySelector("button");

    acceptBtn.onclick = () => {
      //setting cookie for 1 month, after one month it'll be expired automatically
      document.cookie = "CookiesAccepted=true; max-age=" + 60 * 60 * 24 * 30;
      if (document.cookie) { //if cookie is set
        cookieBox.classList.add("hide"); //hide cookie box
      } else { //if cookie not set then alert an error
        alert("Cookie can't be set! Please unblock this site from the cookie setting of your browser.");
      }
    }
    let checkCookie = document.cookie.indexOf("CookiesAccepted=true"); //checking our cookie
    //if cookie is set then hide the cookie box else show it
    checkCookie !== -1 ? cookieBox.classList.add("hide") : cookieBox.classList.remove("hide");


    $(".wrapper").animate({
      top: '30%',
    }, "slow")
  }
}
</script>

<style scoped>
.wrapper{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 365px;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  /*box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.15);*/
  box-shadow: 0 0 10px 4px rgb(0 0 0 / 15%);
  text-align: center;
  z-index: 999;
}
.wrapper.hide{
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: all 0.3s ease;
  display: none;
}
::selection{
  color: #fff;
  background: var(--primary);
}
.wrapper img{
  max-width: 90%;
}
.content header{
  font-size: 25px;
  font-weight: 600;
}
.content{
  margin-top: 10px;
}
.content p{
  color: #858585;
  margin: 5px 0 20px 0;
}
.content .buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons button{
  padding: 10px 20px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  background: var(--primary);
  cursor: pointer;
  transition: all 0.3s ease;
}
.buttons button:hover{
  transform: scale(0.97);
}
.buttons .item{
  margin: 0 10px;
}
.buttons a{
  color: var(--primary);
}

</style>