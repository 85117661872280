<template>
  <h1 class="title" style="text-align: center">Voedingsinformatie</h1>
  <div class="container-nav">
    <router-link to="/NutritionalInformation/Full" class="button-nav button-full">Full</router-link>
    <router-link to="/NutritionalInformation/Allergen" class="button-nav button-allergen">Allergen</router-link>
  </div>
  <div class="content-table">
    <router-view :dishes="dishes"/>
  </div>
</template>

<script>
import NutritionTable from "@/components/NutritionTableFull";

export default {
  name: "NutritionalInformationContent",
  components: {NutritionTable},
  data(){
    return{
      dishes: []
    }
  },
  created() {
    this.dishes = [
      {
        name: 'Kroepoek Udang',
        full: {
          energie: 164,
          vetten: {
            totaal: 4.90,
            verzadigd: 0.00,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 20.30,
            suiker: 1.60
          },
          eiwitten: 9.80,
          zout: 0.60
        },
        allergen: {
          gluten: false,
          schaaldieren: true,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Kroepoek Casave',
        full: {

          energie: 242,
          vetten: {
            totaal: 4.10,
            verzadigd: 0.40,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 45.40,
            suiker: 9.50
          },
          eiwitten: 6.00,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Loempia Vega',
        full: {
          energie: 297,
          vetten: {
            totaal: 25.00,
            verzadigd: 17.90,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 1.50,
            suiker: 1.30
          },
          eiwitten: 18.60,
          zout: 0.410
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Loempia Kip',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Siu Mai',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: true,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: true,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Mandarin Pancake',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Vegan Gyoza',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: true,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Babi Pangang',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: true,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Saté Ayam',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: true,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Tjap Tjoy',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Foe Yong Hai',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: true,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Sticky Chicken',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: true,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Vegan Sweet Chili',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Kip Thaise Rode Curry',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: true,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Koe Loe Kai',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Vegan Zoet Zuur',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: true,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Chinese meatlovers',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: true,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Beef Teriyaki',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Honey Duck',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Witte Rijst',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Nasi Goreng',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Mihoen Goreng',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: true,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Bami Goreng',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: true,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Gestoomde Groenten',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Atjar',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: false,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Spekkoek',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: false,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: true,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
      {
        name: 'Gebakken Banaan',
        full: {
          energie: 303,
          vetten: {
            totaal: 4.80,
            verzadigd: 0.50,
            enkelvoudig: 0.00,
            meervoudig: 0.00
          },
          vezels: 0.00,
          koolhydraten: {
            totaal: 37.20,
            suiker: 4.90
          },
          eiwitten: 8.10,
          zout: 0.10
        },
        allergen: {
          gluten: true,
          schaaldieren: false,
          ei: true,
          vis: false,
          pinda: false,
          soja: false,
          melk: false,
          noten: false,
          selderij: false,
          mosterd: false,
          sesam: false,
          sulfiet: false,
          lupine: false,
          weekdieren: false
        }
      },
    ]
  }
}
</script>

<style scoped>
.title {
  margin-bottom: 50px;
}
.content-table{
  display: flex;
  justify-content: center;
}

.button-nav{
  text-decoration: none;
  color: white;
  padding: 10px;
  background: var(--primary);
  border-radius: 10px;
  margin: 10px;
  width: 100px;
}

.container-nav{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}
</style>