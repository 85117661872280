<template>
  <div class="navbar">
    <div class="navbar-logo">
      <img class="image-full" src="@/assets/WW_LOGO.png" alt="Willie Wong logo" @click="navigateTo('')">
    </div>
    <div class="container-content">
      <div class="navbar-items">
        <div class="navbar-items-left">
          <div class="item-our-food navbar-item" @click="navigateTo('OnsMenu')">
            <p>ONS MENU</p>
          </div>
          <div class="item-our-story navbar-item" @click="navigateTo('OnsVerhaal')">
            <p>ONS VERHAAL</p>
          </div>
          <div class="item-werkenbijwilliewong navbar-item" @click="navigateTo('Careers')">
            <p>WERKEN BIJ WILLIEWONG</p>
          </div>
        </div>
        <div class="navbar-items-right">
          <div class="item-bestel-hier navbar-item" @click="openWindow('https://amstelveen.williewong.nl/')">
            <div class="bestel-hier-container">
              <p>BESTEL HIER!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-collapsable">
    <div id="sidebar" class="sidebar">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
      <a @click="navigateTo('OnsMenu')">ONS MENU</a>
      <a @click="navigateTo('OnsVerhaal')">ONS VERHAAL</a>
            <a @click="navigateTo('Careers')">WERKEN BIJ WILLIEWONG</a>
      <a @click="openWindow('https://amstelveen.williewong.nl/')">BESTEL HIER!</a>
    </div>
    <div class="container-head">
      <button class="openbtn" @click="openNav">&#9776; &nbsp; WILLIE WONG</button>
      <img class="image-collapsable" src="@/assets/WW_LOGO.png" alt="Willie Wong logo" @click="navigateTo('')">
    </div>

  </div>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    openWindow(url) {
      window.open(url)
    },
    navigateTo(path) {
      this.$router.replace('/' + path)
      this.closeNav()
    },
    openNav() {
      document.getElementById("sidebar").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
    },

    /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
    closeNav() {
      document.getElementById("sidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    }
  }
}
</script>

<style scoped>
.navbar-collapsable {
  display: none;
}

.navbar {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  /*margin-top: 50px;*/
  /*z-index: -2;*/
}

.container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background: var(--primary);
  /*z-index: -2;*/
}

.navbar-items-left, .navbar-items-right{
  display: flex;
}

.navbar-items-left div{
  display: flex;
  align-items: center;
}

.navbar-items {
  display: flex;
  width: 55%;
  justify-content: space-between;
  z-index: 100;
}

.navbar-item {
  padding: 15px 40px;
  background: var(--primary);
  color: white;
  z-index: 2;
}

.navbar-item:hover {
  background: white;
  cursor: pointer;
  transition: 0.5s;
  color: var(--primary);
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 10%;
  overflow: visible;
  z-index: 10;
}

.image-full {
  height: 150px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: var(--primary); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 100px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: var(--primary);
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  padding: 20px;
}

.navbar-item:last-child {
  padding: 10px 20px;
}

.bestel-hier-container {
  background: white;
  color: var(--primary);
  padding: 5px 20px;
  border-radius: 10px;
}

/*.navbar-item:last-child:hover {*/
/*  background: var(--primary);*/
/*}*/

.item-bestel-hier {
  justify-self: right;
}

@media screen and (min-width: 750px) and (max-width: 1200px) {
  .navbar-items {
    width: 50%;
  }

  .navbar-item {
    font-size: smaller;
    padding: 15px;
  }

  .navbar-logo {
    left: 0;
  }
}

@media screen and (max-width: 750px) {
  .navbar {
    display: none;
  }

  .navbar-collapsable {
    display: inline;
  }

  .image-collapsable {
    width: 100px;
  }

  .container-head {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background: var(--primary);
    margin-top: 100px;
  }

  .sidebar{
    padding-top: 150px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 100px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

@media screen and (min-width: 820px) {
  .navbar-logo {
    z-index: 102;
  }
}
</style>