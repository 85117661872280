<template>
  <div class="content-homepage">
      <router-view />
  </div>

</template>

<script>

export default {
  name: "Homepage",
}
</script>

<style scoped>

.content-homepage {
  display: flex;
  flex-direction: column;
}


</style>
