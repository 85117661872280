<template>
  <div id="container-section-2">
    <div id="container-slogan-text">
      <h1 id="content-text">WILLIE WONG IS EEN CHINEES-INDISCH AFHAAL/<br>
        BEZORGCONCEPT WAARBIJ WE EEN MODERNE<br>
        DRAAI GEVEN AAN DEZE BEKENDE KEUKEN:</h1>
    </div>
    <div id="container-top">
      <img id="tops-image" width="1000" src="@/assets/gelukskoekjes/tops_3.png" alt="Minder vet, zout en suiker. Minder smaakversterkers. Meer groenten. Duurzame werkwijze."/>
    </div>
    <div id="container-button">
      <ButtonText content-text="Bekijk ons menu"
                  bg-color-container="white"
                  click-action="https://amstelveen.williewong.nl/#menu"
                  text-color="var(--primary)"/>
    </div>
    <div id="container-button-mobile">
      <ButtonText content-text="Bekijk ons menu"
                  width="130px"
                  height="40px"
                  font-size="14"
                  bg-color-container="white"
                  click-action="https://amstelveen.williewong.nl/#menu"
                  text-color="var(--primary)"/>
    </div>
    <div id="container-sketch">
      <img src="@/assets/gelukskoekjes/sketch.png" alt="sketch"/>
      <img id="sketch-2" src="@/assets/gelukskoekjes/sketch.png" alt="sketch"/>
    </div>
  </div>
</template>

<script>
import WWButton from "../WWButton";
import ButtonText from "../buttons/ButtonText";

export default {
  name: "GelukskoekjesSection2",
  components: {ButtonText, WWButton},
  methods: {
    openWindow(url) {
      window.open(url)
    },
  }
}
</script>

<style scoped>

#content-text {
  font-family: scriptorama-markdown, iskra-regular, sans-serif;
  font-weight: 400;
}

#container-section-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px 100px 0 100px;
}

#container-top {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

#container-button, #container-button-mobile{
  margin: 75px 0;
}

#container-button-mobile{
  display: none;
}

h1 {
  color: var(--primary);
  text-align: center;
}

#inline-image {
  vertical-align: middle;
}

#button-bekijk-ons-menu {
  color: var(--primary);
  border: 2px var(--primary) solid;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 65px;
  align-self: center;
  margin-top: 100px;
  font-size: 20px;
}

#container-sketch {
  width: 100%;
  display: flex;
  justify-content: center;
}

#container-sketch img {
  width: 50%;
  margin: 0;
  padding: 0;
}

#sketch-2 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (max-width: 600px) {
  #container-section-2{
    padding: 50px 25px 0 25px;
  }
  #content-text{
    font-size: 24px;
  }

  #tops-image{
    width: 100%;
  }

  #container-button{
    display: none;
  }
  #container-button-mobile{
    display: inline;
  }
}


@media only screen and (min-width: 600px) and (max-width: 992px){
  #tops-image {
    width: 100%;
  }

  #container-sketch img {
    margin-top: 100px;
  }
}

</style>
