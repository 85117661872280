<template>
  <div id="container-bestel-nu">
    <div id="grid-bestel">
      <div class="grid-column" id="grid-left">
        <img id="chopsticks" src="@/assets/gelukskoekjes/stokjes.png" alt="stokjes"/>
      </div>
      <div class="grid-column" id="grid-center">
        <h1>bestel nu bij Willie Wong en vermeld jouw geluksnummer als actiecode</h1>
        <br><br>
        <ButtonText width="142px" height="64px" content-text="Bestel nu"
                    click-action="https://amstelveen.williewong.nl/"
                    bg-color-container="white" text-color="var(--primary)"/>
      </div>
      <div class="grid-column" id="grid-right">
        <img id="noodles" src="@/assets/gelukskoekjes/noodles.png" alt="noodles"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonText from "../buttons/ButtonText";

export default {
  name: "GelukskoekjesSection5",
  components: {ButtonText}
}
</script>

<style scoped>
#container-bestel-nu {
  padding: 100px 240px 200px 240px;
  background: var(--primary);
  color: white;
  text-align: center;
}

h1{
  font-family: scriptorama-markdown, iskra-regular, sans-serif;
  font-weight: 400;
  font-size: 52px;
}
#grid-bestel {
  display: grid;
  grid: "grid-left grid-center grid-right"1fr/
  1fr 2fr 1fr;
}

#chopsticks{
  width: 242px;
}

#noodles{
  width: 242px;
}

.grid-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  #container-bestel-nu{
    padding: 100px 25px;
  }
  #grid-bestel{
    display: flex;
    flex-direction: column;
  }

  h1{
    font-size: 36px;
  }

  #chopsticks, #noodles{
    width: 100px;
  }

  #grid-center{
    padding: 20px 0;
  }
}


@media only screen and (min-width: 600px) and (max-width: 992px){
  #container-bestel-nu {
    padding: 100px 75px 125px 75px;
  }

  #chopsticks, #noodles{
    width: 121px;
  }

  h1{
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  #container-bestel-nu {
    padding: 100px 75px 150px 75px;
  }

  #chopsticks, #noodles{
    width: 121px;
  }

  h1{
    font-size: 38px;
  }
}

</style>
