<template>
  <div class="content" :style="{
      backgroundImage: 'url(' + require('@/assets/willie_wong_dark_background_550x440.jpeg') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }">
    <div class="navbar">
      <Navbar/>
    </div>
    <div class="container-ons-verhaal">
      <h1 style="text-align: center">ONS VERHAAL</h1>
      <div class="container-text">

        <p>Sommigen hebben gezegd dat het tijdperk van de Chinees-Indische keuken voorbij is ...
          Voorbij? Doe niet zo gek!</p>
        <br>
        <p>Wij gaan gewoon door met je favoriete gerechten te bereiden en bij je te bezorgen … wanneer je het minst zin
          hebt
          om te koken, voor verjaardagen, bij een verhuizing of ‘even snel’</p>
        <br>
        <br>
        <p>Het was 1920 toen het eerste Chinees restaurant van Nederland zijn deuren opende in Rotterdam, en sinds die
          tijd
          is de keuken geëvolueerd met Indische invloeden en aangepast om de Nederlandse eetlust te dienen.</p>
        <br>
        <p>Babi Pangang is het ultieme voorbeeld van deze mengelmoes. Een Chinees gerecht met een Indische naam, geliefd
          bij alle Nederlanders. Bij Willie Wong nu zelfs verkrijgbaar in een Vegan versie, hoe gaaf!</p>
        <br>
        <p>Bij Willie Wong eren we deze mengelmoes van de Nederlandse, Chinese en Indische cultuur. Ongeacht hoe het is
          ontstaan, wij serveren de keuken van deze mengelmoes met trots. </p>
        <br>
        <p>Wij hebben met liefde gewerkt om nieuwe versies van de klassieke Chinees-Indische gerechten te bereiden. Wij
          hebben onze recepturen samengesteld met het oog op gezonder, lichter en duurzamer eten en bieden wij meer
          vegetarische/ veganistische opties, en minder vet, suiker en zout in onze gerechten. </p>
        <br>
        <p>Of je nu de eettafel wilt vullen met Aziatische gerechten voor het hele gezin of gewoon jezelf wilt laten
          verwennen, bij Willie Wong zit je altijd goed. Het is eten waar we zelf 100 % van houden: heerlijk herkenbaar
          maar lekkerder dan je kan herinneren, wacht maar tot je je eerste hap hebt gehad ;).</p>
        <br>
        <p>Welkom bij Willie Wong. Wij kunnen niet wachten op je eerste bestelling… en je tweede en je derde en je
          vierde.</p>
        <br><br>
      </div>
    </div>
  </div>
  <footer>
    <Footer/>
  </footer>

</template>

<script>
import Footer from "./Footer";
import Navbar from "./Navbar";

export default {
  name: "Onsverhaal",
  components: {Navbar, Footer}
}
</script>

<style scoped>

.container-ons-verhaal {
  max-width: 40%;
  margin: auto;
  color: white;
}

.container-text {
  text-align: center;
  margin-top: 100px;
}

@media screen and (max-width: 600px) {
  .container-ons-verhaal {
    max-width: 80%;
  }
}
</style>
