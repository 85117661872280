<template>
  <div class="button-container" @click="onClick"
       :style="{
    width: width,
    height: height,
    justifyContent: justifyContent,
    alignItems: alignItems,
    backgroundColor: bgColorContainer,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: flexDirection,
    borderRadius: borderRadius,
    border: border,
    borderColor: borderColor
  }">
    <p :style="{margin: textMargins,fontSize: fontSize+'px', color: textColor}">{{ contentText }}</p>
  </div>
</template>

<script>
export default {
  name: "ButtonText",
  props: {
    clickAction: {
      default: undefined,
      type: String
    },
    width: {
      default: '200px',
      type: String
    },
    height: {
      default: '64px',
      type: String
    },
    fontSize: {
      default: 20,
      type: Number,
    },
    textColor: {
      default: 'white',
      type: String,
    },
    bgColorContainer: {
      default: 'var(--primary)',
      type: String,
    },
    contentText: {
      default: '',
      type: String,
    },
    flexDirection: {
      default: 'row',
      type: String,
    },
    borderRadius: {
      default: '4px',
      type: Number,
    },
    borderColor: {
      default: 'var(--primary)',
      type: String,
    },
    border: {
      default: '2px solid var(--primary)'
    },
    alignItems: {
      default: 'center',
      type: String,
    },
    textMargins: {
      default: '0',
      type: String,
    },
    justifyContent: {
      default: 'center',
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      if (this.clickAction !== undefined) {
        window.open(this.clickAction)
      }
    }
  }
}
</script>

<style scoped>

</style>
